<template>
    <div class="rbcomp">
        <button @click="questioAcNo" class="acnoButton">
            <i class="fa fa-question-circle"></i> Account inquiry
        </button>
        <router-link to=/recharge tag="button" class="rechargeButton">
            <i class="fa fa-plus-square"></i> CASH IN
        </router-link>
        <router-link to=/exchange tag="button" class="exchangeButton">
            <i class="fa fa-minus-square"></i> CASH OUT
        </router-link>
    </div>
</template>

<script>
    import {RECEIVE_HIDE_LOADING, RECEIVE_SHOW_LOADING} from "../store/mutation-types";
    import {saveQuestion} from "../network/userRequest";
    import sportsConst from "../common/sportsConst";

    export default {
        name: "ApplyButtonsComp",
        data() {
            return {}
        },
        methods: {
            questioAcNo() {
                this.$store.commit(RECEIVE_SHOW_LOADING)
                //let content = '<p>계좌번호 문의합니다</p>'
                let content = '<p>Please let me know BANK ACCOUNT for CASH IN</p>'
                saveQuestion({'content': content, 'type': sportsConst.CUSTOMER_TYPE_BANK_CARD}).then(res => {
                    this.$store.commit(RECEIVE_HIDE_LOADING)
                    if (res.data.success) {
                        this.content = ''
                        this.$swal({
                            title: '계좌번호 문의글 작성이 완료되였습니다',
                            type: 'success',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                        this.$router.push('/customer_center')

                    } else {
                        this.$swal({
                            title: res.data.msg,
                            type: 'error',
                            showCancelButton: false,
                            showConfirmButton: true
                        })
                    }
                })
            },
        }
    }
</script>

<style scoped>
    .rbcomp {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        width: 100%;
        margin: 5px 0
    }

    .acnoButton {
        width: 33%;
        height: 33px;
        line-height: 28px;
        border-radius: 5px;
        background-color: #9c0e0d
    }

    .rechargeButton {
        width: 33%;
        height: 33px;
        line-height: 28px;
        border-radius: 5px;
        background-color: #3b5d77
    }

    .exchangeButton {
        width: 33%;
        height: 33px;
        line-height: 28px;
        border-radius: 5px;
        background-color: #17b9bb
    }
</style>